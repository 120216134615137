.modalEncerrar{
    display: flex;
    align-items: center;
    position: fixed;
    top:0;
    bottom:0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
}

.modalEncerrar-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: #FFF;
    top:0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 4em 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 1);
}

.modalEncerrar-componentes{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.modalEncerrar-container h1{
    font-size: 30px;
    margin-top: 10px;
}

.modalEncerrar-componentes span{
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

.modalEncerrar-componentes input{
    font-size: 25px;
    border-radius: 9px;
    padding: 5px;
    margin-top: 5px;
    background-color: #DDD;
}

.modalEncerrar-componentes select{
    font-size: 25px;
    border-radius: 9px;
    padding: 5px;
    margin-top: 5px;
}
.modalEncerrar-botao{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modalEncerrar-botao button{
    width: 150px;
    height: 35px;
    border: 0;
    border-radius: 7px;
    background-color: #DC4405;
    color: #FFF;
    font-weight: bold;
    font-size: 15px;
}

@media screen and (max-width:700px) {
    .modalEncerrar-container{
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: center;
        position: fixed;
        width: 400px;
        background-color: #FFF;
        top:15%;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 4em 2rem;
        box-shadow: 0 0 20px rgba(0, 0, 0, 1);
    }
}