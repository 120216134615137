@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*:focus{
    outline: 0;
}

html,body,#root{
    height: 100%;
}

body{
    font: 14px 'Roboto', sans-serif;
    background-color: #FFF;
}

a{
    text-decoration: none;
}

ul{
    list-style: none;
}

button{
    cursor: pointer;
}