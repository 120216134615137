.pedidosdash-container{
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #EFEFEC;
    height: 100%;
}

.pedidosdash-container-pedidos{
    margin-left: 230px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100%;
    border-radius: 9px;
    background-color: #FFF;
}

.pedidosdash-container-pedidos h1{
    background-color: #DC4405;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    color: #FFF;
    padding: 10px;
}

.container-vazio{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    height: 250px;
    border: 1px solid black;
    background-color: #FFF;
    margin-bottom: 10px;
    padding: 8px;
}
.container-vazio h1{
    font-size: 25px;
    border-radius: 9px;
    color: #000;
    background-color: #FFF;
}
.container-vazio a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #FFF;
    font-size: 18px;
    border-radius: 9px;
    background-color: #DC4405;
    padding: 10px;
    transition: ease-in 0.2s;
}
.container-vazio a:hover{
    background-color: #FFF;
    color: #F28F1A;
    transition: scale(1.1);
}

.container-vazio svg{
    margin-right: 5px;
}

table{
    border: 1px solid #CCC;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

table caption{
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

table tr{
    background-color: #f8f8f8;
    border:  1px solid #ddd;
    padding: .35em;
}

table th,td{
    padding: .62em;
    text-align: center;
}
table th{
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}

table td .action{
    border:0;
    padding: 5px;
    margin-right: 2px;
    align-items: center;
    display: inline-block;
    border-radius: 9px;
}

table td .action svg{
    vertical-align: middle;
}

table td .action svg{
    vertical-align: middle;
}

table td .status-fornecedor{
    padding: 3px;
    border-radius: 9px;
    color: #FFF;
}
@media screen and (max-width:600px) {
    table{
        border: 0;
    }
    table caption{
        font-size: 1.3em;
    }
    table thead{
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr{
        border-bottom: 3px solid #DDD;
        display: block;
        margin-bottom: .65em;
    }
    
    table td{
        border-bottom: 1px solid #DDD;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    table td::before{
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
    table td:last-child{
        border-bottom: 0;
    }
}



@media screen and (max-width:700px) {
    .pedidosdash-container{
        margin-left: 0;
        width: 100%;
        padding: 0;
        background-color: #EFEFEC;
        height: 100%;
    }
    .pedidosdash-container-pedidos{
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 5px;
        margin-bottom: 10px;
        height: 100%;
        border-radius: 9px;
        background-color: #FFF;
    }
    .container-vazio{
        margin: 10px;
    }
}