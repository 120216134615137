.solicitarcontainer{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.solicitar-interno{
    margin-left: 170px;
    padding: 1px 16px;
    height: 100%;
    padding: 10px;
    background-color: #EFEFEC;
}
.solicitar-areacomponentes{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-left: 50px;
    border-radius: 10px;
}
.solicitar-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
    background-color: #DC4405;
    color: white;
    border-radius: 10px 10px 0 0;
}
.solicitar-header h3{
    font-size: 28px;
}
.pedido-cadastro{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-width: 40%;
    width: 100%;
    min-height: 500px;
    background-color: #FFF;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    border: 1px solid #000000;
}

.actionSolicitar{
    margin-top: 10px;
    background-color: #DC4405;
    border: 0;
    width: 100px;
    height: 30px;
    padding: 5px;
    border-radius: 9px;
    color: #FFF;
    transition: 0.1s;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.288);
}

.actionSolicitar:hover{
    background-color: #FFF;
    color: #F28F1A;    
}

.form-container{
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    max-width: 900px;
}

.solicitar-pedidos-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    column-gap: 10px;
}

.input-container{
    width: 450px;
    height: 90px;
}

.input-container h3{
    font-size: 25px;
    margin-bottom: 5px;
}

.input-container > input, .input-container > select {
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
    width: 100%;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #000;
}

.input-container select {
    font-size: 15px;
}

.button-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;
}
@media screen and (max-width: 1051px) {
    .button-container{
        justify-content: center;
    }

    .input-container{
        width: 350px;
    }
}

@media screen and (max-width:700px) {
    div.solicitar-interno, .solicitar-areacomponentes{
        margin-left: 0;
    }

    .input-container{
        width: 250px;
    }

    .form-container{
        padding: 0;
    }
}