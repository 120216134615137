.area-signin{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DC4405;
}
.area-login{
    background-color: #FFF;
    display:flex;
    min-width: 30%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    padding: 5px;
}
.area-logo{
    display: flex;
    justify-content: center;
    width: 100%;
}
.area-logo img{
    padding: 20px;
    height: 130px;
    width: 170px;
}

form{
    display:flex;
    flex-direction: column;
    width: 90%;
    padding: 5px;
}

.area-login input{
    margin-bottom: 5px;
    height: 35px;
    border: 0;
    border-radius: 7px;
    padding: 10px;
    font-size: 15px;
    background-color: #EFEFEC;
}

.area-login button{
    height: 35px;
    border: 0;
    border-radius: 7px;
    background-color: #1E90FF;
    color: #FFF;
    font-weight: bold;
    font-size: 15px;
}